import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts

import DashboardLayout from "../layouts/dashboard";

// guards
import GuestGuard from "../guards/GuestGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import AuthGuard from "../guards/AuthGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: "/", element: <Navigate to="/dashboard/app" replace /> },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //     path: 'register',
        //     element: (
        //         <GuestGuard>
        //             <Register/>
        //         </GuestGuard>
        //     ),
        // },
        /*{path: 'login-unprotected', element: <Login/>},
                {path: 'register-unprotected', element: <Register/>},
                {path: 'reset-password', element: <ResetPassword/>},
                {path: 'verify', element: <VerifyCode/>},*/
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: "app", element: <GeneralApp /> }],
    },
    // blog Routes
    {
      path: "/blog",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "blog",
          children: [
            {
              element: <Navigate to="/blog/blog/list" replace />,
              index: true,
            },
            { path: "list", element: <BlogList /> },
            { path: "list/create", element: <BlogListCreate /> },
            { path: "list/:id/edit", element: <BlogListCreate /> },
          ],
        },
        {
          path: "blogCategory",
          children: [
            {
              element: <Navigate to="/blog/blogCategory/list" replace />,
              index: true,
            },
            { path: "list", element: <BlogCategoryList /> },
            { path: "list/create", element: <BlogCategoryCreate /> },
            { path: "list/:id/edit", element: <BlogCategoryCreate /> },
          ],
        },
        {
          path: "blogTag",
          children: [
            {
              element: <Navigate to="/blog/blogTag/list" replace />,
              index: true,
            },
            { path: "list", element: <BlogTagList /> },
            { path: "list/create", element: <BlogTagCreate /> },
            { path: "list/:id/edit", element: <BlogTagCreate /> },
          ],
        },
      ],
    },
    // store Routes
    {
      path: "/store",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "mainCategory",
          children: [
            {
              element: <Navigate to="/store/mainCategory/list" replace />,
              index: true,
            },
            { path: "list", element: <MainCategoryList /> },
            { path: "list/create", element: <MainCategoryCreate /> },
            { path: "list/:id/edit", element: <MainCategoryCreate /> },
          ],
        },
        {
          path: "pageInfo",
          children: [
            {
              element: <Navigate to="/store/pageInfo/list" replace />,
              index: true,
            },
            { path: "list", element: <PageInfoList /> },
            { path: "list/create", element: <PageInfoCreate /> },
            { path: "list/:id/edit", element: <PageInfoCreate /> },
            {
              path: "pageInfoCategory",
              children: [
                {
                  element: (
                    <Navigate to="/store/pageInfoCategory/list" replace />
                  ),
                  index: true,
                },
                { path: "list", element: <PageInfoCategoryList /> },
                { path: "list/create", element: <PageInfoCategoryCreate /> },
                { path: "list/:id/edit", element: <PageInfoCategoryCreate /> },
              ],
            },
          ],
        },
        {
          path: "category",
          children: [
            {
              element: <Navigate to="/store/category/list" replace />,
              index: true,
            },
            { path: "list", element: <StoresCategoryList /> },
            { path: "list/create", element: <CategoryCreate /> },
            { path: "list/:id/edit", element: <CategoryCreate /> },
          ],
        },
        {
          path: "products",
          children: [
            {
              element: <Navigate to="/store/products/list" replace />,
              index: true,
            },
            { path: "list", element: <StoreProducts /> },
            { path: "list/create", element: <StoreProductsCreate /> },
            { path: "list/:slug/:id/edit", element: <StoreProductsCreate /> },

            { path: "detail", element: <StoreProductsAttr /> },
            { path: "detail/create", element: <ProductsAttrCreate /> },
            { path: "detail/:id/edit", element: <ProductsAttrCreate /> },
            {
              path: "detail/:attrId/from-product",
              element: <ProductsAttrCreate />,
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              element: <Navigate to="/store/users/list" replace />,
              index: true,
            },
            { path: "list", element: <UserList /> },
            { path: "list/created", element: <UserCreated /> },
            { path: "list/:id/users/edit", element: <UserCreated /> },
            { path: "list/:id/edit", element: <UserCreate /> },
            { path: "list/:id/status", element: <UserStatus /> },
            { path: "list/:id/attributes", element: <UserInfoAttributes /> },
          ],
        },
        {
          path: "userAttributes",
          children: [
            {
              element: <Navigate to="/store/userAttributes/list" replace />,
              index: true,
            },
            { path: "list", element: <UserAttributesList /> },
            { path: "list/create", element: <UserAttributesCreate /> },
            { path: "list/:id/edit", element: <UserAttributesCreate /> },
          ],
        },
        {
          path: "roles",
          children: [
            {
              element: <Navigate to="/store/roles/list" replace />,
              index: true,
            },
            { path: "list", element: <RolesList /> },
            { path: "list/create", element: <RolesCreate /> },
            { path: "list/:id/edit", element: <RolesCreate /> },
          ],
        },
        {
          path: "permission",
          children: [
            {
              element: <Navigate to="/store/permission/list" replace />,
              index: true,
            },
            { path: "list", element: <PermissionList /> },
            { path: "list/create", element: <PermissionCreate /> },
            { path: "list/:id/edit", element: <PermissionCreate /> },
          ],
        },
        {
          path: "profiles",
          children: [
            {
              element: <Navigate to="/store/profiles/list" replace />,
              index: true,
            },
            { path: "list", element: <ProfilesList /> },
            { path: "list/create", element: <ProfilesCreate /> },
            { path: "list/:id/edit", element: <ProfilesCreate /> },
          ],
        },
        {
          path: "license",
          children: [
            {
              element: <Navigate to="/store/license/list" replace />,
              index: true,
            },
            { path: "list", element: <LicenseList /> },
            { path: "list/create", element: <LicenseCreate /> },
            { path: "list/:id/edit", element: <LicenseCreate /> },

            { path: "products", element: <ProductWithLicenseList /> },
            { path: "products/create", element: <ProductWithLicenseCreate /> },
            {
              path: "products/:id/edit",
              element: <ProductWithLicenseCreate />,
            },
            {
              path: "products/:attrId/from-product",
              element: <ProductWithLicenseCreate />,
            },
          ],
        },
        {
          path: "consult",
          children: [
            {
              element: <Navigate to="/store/consult/list" replace />,
              index: true,
            },
            { path: "list", element: <ConsultList /> },
            // {path: "list/create", element: <ConsultCreate/>},
            // {path: "list/:id/edit", element: <ConsultCreate/>},
          ],
        },
        {
          path: "transaction",
          children: [
            {
              element: <Navigate to="/store/transaction/list" replace />,
              index: true,
            },
            { path: "list", element: <TransactionList /> },
            // {path: "list/create", element: <TransactionCreate/>},
            // {path: "list/:id/edit", element: <TransactionCreate/>},
          ],
        },
        {
          path: "faq",
          children: [
            {
              element: <Navigate to="/store/faq/list" replace />,
              index: true,
            },
            { path: "list", element: <FAQList /> },
            { path: "list/create", element: <FAQListCreate /> },
            { path: "list/:id/edit", element: <FAQListCreate /> },
            { path: "list/:attrId/from-product", element: <FAQListCreate /> },

            { path: "category", element: <FAQCategoryList /> },
            { path: "category/create", element: <FAQCategoryCreate /> },
            { path: "category/:id/edit", element: <FAQCategoryCreate /> },
          ],
        },
        {
          path: "questionSurvey",
          children: [
            {
              element: <Navigate to="/store/questionSurvey/list" replace />,
              index: true,
            },
            { path: "list", element: <QuestionSurveyList /> },
            { path: "list/create", element: <QuestionSurveyListCreate /> },
            { path: "list/:id/edit", element: <QuestionSurveyListCreate /> },
          ],
        },
        {
          path: "ticket",
          children: [
            {
              element: <Navigate to="/store/ticket/list" replace />,
              index: true,
            },
            { path: "list", element: <TicketsList /> },
            { path: "list/:id/edit", element: <TicketAnswerCreate /> },
            // { path: "list/create", element: <QuestionSurveyListCreate /> },
          ],
        },
        {
          path: "ticketCategory",
          children: [
            {
              element: <Navigate to="/store/ticketCategory/list" replace />,
              index: true,
            },
            { path: "list", element: <TicketCategoryList /> },
            { path: "list/:id/edit", element: <TicketCategoryListCreate /> },
            { path: "list/create", element: <TicketCategoryListCreate /> },
          ],
        },
        {
          path: "topic",
          children: [
            {
              element: <Navigate to="/store/topic/list" replace />,
              index: true,
            },
            // { path: "list", element: <TicketCategoryList /> },
            { path: "list/:id/edit", element: <TopicListCreate /> },
            { path: "list/:attrId/from-product", element: <TopicListCreate /> },
          ],
        },
        {
          path: "userDoc",
          children: [
            {
              element: <Navigate to="/store/userDoc/list" replace />,
              index: true,
            },
            { path: "list", element: <UserDocList /> },
            { path: "list/create", element: <UserDocListCreate /> },
            { path: "list/:id/edit", element: <UserDocListCreate /> },
          ],
        },
        {
          path: "lms",
          children: [
            {
              element: <Navigate to="/store/lms/list" replace />,
              index: true,
            },
            { path: "list", element: <LmsList /> },
            { path: "list/create", element: <LmsListCreate /> },
            { path: "list/:id/edit", element: <LmsListCreate /> },
          ],
        },
        {
          path: "media",
          children: [
            {
              element: <Navigate to="/store/media/list" replace />,
              index: true,
            },
            { path: "list", element: <MediaList /> },
            { path: "list/create", element: <MediaCreate /> },
            { path: "list/:id/edit", element: <MediaCreate /> },
          ],
        },
      ],
    },

    // Main Routes

    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
//
// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// // GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

// Store

// // Main Category
const MainCategoryList = Loadable(
  lazy(() => import("../pages/store/mainCategory/mainCategoryList"))
);
const MainCategoryCreate = Loadable(
  lazy(() => import("../pages/store/mainCategory/mainCategoryCreate"))
);
// // Category
const StoresCategoryList = Loadable(
  lazy(() => import("../pages/store/category/categoriesList"))
);
const CategoryCreate = Loadable(
  lazy(() => import("../pages/store/category/categoryCreate"))
);

// // Products
const StoreProducts = Loadable(
  lazy(() => import("../pages/store/products/productsList/productsList"))
);
const StoreProductsCreate = Loadable(
  lazy(() => import("../pages/store/products/productsList/productCreate"))
);
const StoreProductsAttr = Loadable(
  lazy(() => import("../pages/store/products/productDetail/productsDetail"))
);
const ProductsAttrCreate = Loadable(
  lazy(() =>
    import("../pages/store/products/productDetail/productDetailCreate")
  )
);

// // Roles
const RolesList = Loadable(
  lazy(() => import("../pages/store/roles/rolesList"))
);
const RolesCreate = Loadable(
  lazy(() => import("../pages/store/roles/rolesCreate"))
);
// // PageInfo
const PageInfoList = Loadable(
  lazy(() => import("../pages/store/pageInfo/pageInfoList/pageInfoList"))
);
const PageInfoCreate = Loadable(
  lazy(() => import("../pages/store/pageInfo/pageInfoList/pageInfoCreate"))
);
const PageInfoCategoryList = Loadable(
  lazy(() =>
    import("../pages/store/pageInfo/pageInfoCategory/pageInfoCategoryList")
  )
);
const PageInfoCategoryCreate = Loadable(
  lazy(() =>
    import("../pages/store/pageInfo/pageInfoCategory/pageInfoCategoryCreate")
  )
);
// // Permission
const PermissionList = Loadable(
  lazy(() => import("../pages/store/permission/permissionList"))
);

const PermissionCreate = Loadable(
  lazy(() => import("../pages/store/permission/permissionCreate"))
);
// // Profiles
const ProfilesList = Loadable(
  lazy(() => import("../pages/store/profiles/profilesList"))
);

const ProfilesCreate = Loadable(
  lazy(() => import("../pages/store/profiles/profilesCreate"))
);
// // users
const UserList = Loadable(lazy(() => import("../pages/store/users/usersList")));
const UserCreate = Loadable(
  lazy(() => import("../pages/store/users/usersCreate"))
);
const UserCreated = Loadable(
  lazy(() => import("../pages/store/users/userCreated"))
);
const UserStatus = Loadable(
  lazy(() => import("../pages/store/users/userDocStatus"))
);
const UserInfoAttributes = Loadable(
  lazy(() => import("../pages/store/users/userInfoAttributes"))
);
// // userAttributes
const UserAttributesList = Loadable(
  lazy(() => import("../pages/store/userAttributes/userAttributesList"))
);
const UserAttributesCreate = Loadable(
  lazy(() => import("../pages/store/userAttributes/userAttributesCreate"))
);
// // license
const LicenseList = Loadable(
  lazy(() => import("../pages/store/license/licenseList/licenseList"))
);
const LicenseCreate = Loadable(
  lazy(() => import("../pages/store/license/licenseList/licenseCreate"))
);
const ProductWithLicenseList = Loadable(
  lazy(() =>
    import("../pages/store/license/productWithLicense/productWithLicenseList")
  )
);
const ProductWithLicenseCreate = Loadable(
  lazy(() =>
    import("../pages/store/license/productWithLicense/productWithLicenseCreate")
  )
);
// // consult
const ConsultList = Loadable(
  lazy(() => import("../pages/store/consult/consultList"))
);

// // transactions
const TransactionList = Loadable(
  lazy(() => import("../pages/store/transactions/transactionsList"))
);
// // userDoc
const UserDocList = Loadable(
  lazy(() => import("../pages/store/userDoc/userDocList"))
);
const UserDocListCreate = Loadable(
  lazy(() => import("../pages/store/userDoc/userDocCreate"))
);
// // faq
const FAQList = Loadable(
  lazy(() => import("../pages/store/faq/faqList/faqList"))
);
const FAQListCreate = Loadable(
  lazy(() => import("../pages/store/faq/faqList/faqCreate"))
);
const FAQCategoryList = Loadable(
  lazy(() => import("../pages/store/faq/faqCategory/faqCategoryList"))
);
const FAQCategoryCreate = Loadable(
  lazy(() => import("../pages/store/faq/faqCategory/faqCategoryCreate"))
);
//questionSurvey
const QuestionSurveyList = Loadable(
  lazy(() => import("../pages/store/questionSurvey/questionSurveyList"))
);
const QuestionSurveyListCreate = Loadable(
  lazy(() => import("../pages/store/questionSurvey/questionSurveyCreate"))
);
//topic
const TopicListCreate = Loadable(
  lazy(() => import("../pages/store/topic/topicCreate"))
);
//tickets
const TicketsList = Loadable(
  lazy(() => import("../pages/store/tickets/ticketsList/tickestList"))
);
const TicketAnswerCreate = Loadable(
  lazy(() => import("../pages/store/tickets/ticketsList/ticketAnswerCreate"))
);
//lms
const LmsList = Loadable(lazy(() => import("../pages/store/lms/lmsList")));
const LmsListCreate = Loadable(
  lazy(() => import("../pages/store/lms/lmsCreate"))
);
//blog
const BlogList = Loadable(
  lazy(() => import("../pages/blog/blog/blogsList/blogList"))
);
const BlogListCreate = Loadable(
  lazy(() => import("../pages/blog/blog/blogsList/blogCreate"))
);
//BlogCategory
const BlogCategoryList = Loadable(
  lazy(() => import("../pages/blog/blog/blogsCategory/blogCategoryList"))
);
const BlogCategoryCreate = Loadable(
  lazy(() => import("../pages/blog/blog/blogsCategory/blogCategoryCreate"))
);
//BlogTag
const BlogTagList = Loadable(
  lazy(() => import("../pages/blog/blog/blogsTag/blogTagList"))
);
const BlogTagCreate = Loadable(
  lazy(() => import("../pages/blog/blog/blogsTag/blogTagCreate"))
);
//TicketCategory
const TicketCategoryList = Loadable(
  lazy(() => import("../pages/store/ticketCategory/ticketCategoryList"))
);
const TicketCategoryListCreate = Loadable(
  lazy(() => import("../pages/store/ticketCategory/ticketCategoryCreate"))
);

//media
const MediaList = Loadable(
  lazy(() => import("../pages/store/media/mediaList"))
);
const MediaCreate = Loadable(
  lazy(() => import("../pages/store/media/mediaCreate"))
);
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
