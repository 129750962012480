const fa = {
  store: {
    title: "فروشگاه",
    category: "دسته بندی ها",
    mainCategory: "دسته بندی اصلی",
    categories: {
      title: "دسته بندی",
      create: "ایجاد",
      edit: "ویرایش",
    },
    pageInfo: {
      title: "صفحه ها",
      titleList: "لیست صفحه ها",
      titleCategory: "دسته بندی صفحه ها",
    },
    products: {
      title: "محصولات",
      list: "لیست محصولات",
      detail: "ویژگی محصولات",
      topics: "سرفصل",
      attribute: {
        title: "ویژگی محصولات",
        create: "افزودن",
      },
    },
    roles: {
      title: "نقش ها",
    },
    profiles: {
      title: "پروفایل",
    },
    topic: {
      title: "سرفصل",
    },
    permission: {
      title: "دسترسی",
    },
    users: {
      title: "کاربران",
      titleUser: "فایل آپلود شده",
      userAttributes: "ویژگی کاربران",
    },
    license: {
      title: "گواهی نامه ها",
      list: "لیست گواهی ها",
      productList: "محصولات دارای گواهی",
    },
    consult: {
      title: "درخواست مشاوره",
    },
    transaction: {
      title: "تراکنش ها",
    },
    faq: {
      title: "سوالات متداول",
      list: "لیست سوالات",
      category: "دسته بندی سوالات",
    },
    questionSurvey: {
      title: "سوالات نظرسنجی",
    },
    ticket: {
      title: "لیست تیکت ها",
      placeHolder: "ارسال پاسخ تیکت",
      closedPlaceHolder: "تیکت بسته شده است",
      cancel: "انصراف",
      close: "بستن تیکت",
      update: "بروزرسانی",
      status: "وضعیت",
      ticketNumber: "شماره تیکت",
      seenBy: "دیده شده توسط",
      editMode: "حالت ادیت",
      sendFile: "ارسال فایل",
      filterSearchPlaceholder: "جستجوی شماره تیکت یا موضوع",
    },
    categoryTicket: {
      title: "دسته بندی تیکت ها",
    },
    lms: {
      title: "دسته بندی امتحان ها",
      titleLms: "لیست امتحان ها",
    },
    userDoc: {
      title: "دسته بندی اسناد",
    },
    common: {
      create: "افزودن",
      edit: "ویرایش",
      delete: "حذف",
      back: "بازگشت",
      upload: "بارگذاری",
      confirmNew: "ثبت",
      confirmSave: "ذخیره",
      confirmEdit: "ذخیره",
      confirmSaveEdit: "ذخیره و خروج",
      refresh: "تازه سازی",
    },
    media:{
      title:'رسانه',

    }
  },
  dashboard: {
    title: "داشبورد",
    tableCommon: {
      rowPerPage: "ردیف در هر صفحه",
      noData: "داده ای یافت نشد",
      saveButton: "ذخیره تغییرات",
      dense: "متراکم",
      view: "مشاهده",
      edit: "ویرایش",
      delete: "حذف",
    },
  },
  blog: {
    title: "دانشنامه",
    blog: {
      title: "بلاگ",
      titleBlog: "نوشتن بلاگ",
      categoriesBlog: "دسته بندی بلاگ",
      TagBlog: "دسته بندی تگ بلاگ",
    },
  },
  supplier: {
    management: {
      title: "پنل مدیریت",
      settings: {
        title: "مشخصات تامین کننده",
        settings: {
          title: "مشخصات",
          confirm: "ثبت مشخصات",
        },
      },
      search: "جست و جو .....",
      users: {
        search: "جست و جو بر اساس اسم فارسی ...",
      },
      products: {
        title: "محصولات",
        catsList: {
          title: "لیست دسته بندی ها",
          create: "افزودن دسته بندی",
          edit: "ویرایش دسته بندی",
          search: "جست و جو نام دسته بندی ...",
        },
        productsList: {
          title: "لیست محصولات",
          create: "افزودن محصول",
          edit: "ویرایش محصول",
          search: "جست و جو نام محصول ...",
        },
        productsOrderList: {
          title: "ترتیب محصولات",
        },
      },
      personalStore: {
        title: "فروشگاه اختصاصی",
        storeSettings: {
          title: "مشخصات",
          confirm: "ثبت مشخصات",
        },
        discounts: {
          title: "کد تخفیف فروشگاه اختصاصی",
          create: "ایجاد کد تخفیف",
          edit: "ویرایش کد تخفیف",
          search: "جست و جو کد تخفیف ...",
        },
        orders: {
          title: "سفارشات فروشگاه اختصاصی",
          edit: "ویرایش سفارش",
          detail: "مشاهده سفارش",
          detailPage: "جزئیات سفارش",
          search: "جست و جو نام کاربر یا شماره فاکتور ...",
        },
      },
      stores: {
        title: "فروشگاه ها",
        storesList: {
          title: "لیست فروشگاه ها",
          create: "ایجاد فروشگاه",
          edit: "ویرایش فروشگاه",
          search: "جست و جو نام فروشگاه ...",
        },
      },
      storesSite: {
        title: "سایت فروشگاه ها",
        campaign: {
          title: "کمپین ها",
          create: "ایجاد کمپین",
          edit: "ویرایش کمپین",
          search: "جست و جوی نام کمپین ...",
        },
        slider: {
          title: "اسلایدرها",
          create: "افزودن اسلایدر",
          edit: "ویرایش اسلایدر",
          slide: {
            title: "اسلایدها",
            create: "افزودن اسلاید",
            edit: "ویرایش اسلاید",
          },
        },
        discounts: {
          title: "کد تخفیف دیگر فروشگاه ها",
          create: "ایجاد کد تخفیف",
          edit: "ویرایش کد تخفیف",
          search: "جست و جو کد تخفیف ...",
        },
        orders: {
          title: "سفارشات دیگر فروشگاه ها",
          edit: "ویرایش سفارش",
          detail: "مشاهده سفارش",
          detailPage: "جزئیات سفارش",
          search: "جست و جو نام کاربر یا شماره فاکتور ...",
        },
      },
    },
  },
};

export default fa;
