// components
import { PATH_STORE, PATH_BLOG } from "routes/paths";
import fa from "locales/fa";
import SvgIconStyle from "components/SvgIconStyle";
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  main_category: getIcon("ic_main_category"),
  category: getIcon("ic_category"),
  ecommerce: getIcon("ic_ecommerce"),
  product: getIcon("ic_product"),
  roles: getIcon("ic_roles"),
  permission: getIcon("ic_permission"),
  license: getIcon("ic_license"),
  users: getIcon("ic_users"),
  consult: getIcon("ic_consult"),
  faq: getIcon("ic_faq"),
  survey: getIcon("ic_survey"),
  ticket: getIcon("ic_ticket"),
  userDoc: getIcon("ic_userDoc"),
  lms: getIcon("ic_Lms"),
  blog: getIcon("ic_blog"),
  pageInfo: getIcon("ic_pageInfo"),
  userAttributes: getIcon("ic_attribute"),
  media: getIcon("ic_media"),
  profiles: getIcon("ic_profiles"),
};

const t = fa;

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: t.store.title,
    items: [
      {
        title: t.store.pageInfo.title,
        path: PATH_STORE.pageInfo.root,
        icon: ICONS.pageInfo,
        children: [
          {
            title: t.store.pageInfo.titleList,
            path: PATH_STORE.pageInfo.pageInfoList.root,
          },
          {
            title: t.store.pageInfo.titleCategory,
            path: PATH_STORE.pageInfo.pageInfoCategory.root,
          },
        ],
      },
      {
        title: t.store.mainCategory,
        path: PATH_STORE.mainCategory.root,
        icon: ICONS.main_category,
      },
      {
        title: t.store.category,
        path: PATH_STORE.category.root,
        icon: ICONS.category,
      },
      {
        title: t.store.products.title,
        path: PATH_STORE.products.root,
        icon: ICONS.product,
        children: [
          {
            title: t.store.products.list,
            path: PATH_STORE.products.productsList.root,
          },
          {
            title: t.store.products.detail,
            path: PATH_STORE.products.productAttribute.detail,
          },
        ],
      },
      {
        title: t.store.users.title,
        path: PATH_STORE.users.root,
        icon: ICONS.users,
      },
      {
        title: t.store.users.userAttributes,
        path: PATH_STORE.userAttributes.root,
        icon: ICONS.userAttributes,
      },
      {
        title: t.store.roles.title,
        path: PATH_STORE.roles.root,
        icon: ICONS.roles,
      },
      {
        title: t.store.permission.title,
        path: PATH_STORE.permission.root,
        icon: ICONS.permission,
      },
      {
        title: t.store.profiles.title,
        path: PATH_STORE.profiles.root,
        icon: ICONS.profiles,
      },
      {
        title: t.store.license.title,
        path: PATH_STORE.license.root,
        icon: ICONS.license,
        children: [
          {
            title: t.store.license.list,
            path: PATH_STORE.license.licenseList.root,
          },
          {
            title: t.store.license.productList,
            path: PATH_STORE.license.products.root,
          },
        ],
      },
      {
        title: t.store.consult.title,
        path: PATH_STORE.consult.root,
        icon: ICONS.consult,
      },
      {
        title: t.store.transaction.title,
        path: PATH_STORE.transaction.root,
        icon: ICONS.ecommerce,
      },

      {
        title: t.store.faq.title,
        path: PATH_STORE.faq.root,
        icon: ICONS.faq,
        children: [
          {
            title: t.store.faq.category,
            path: PATH_STORE.faq.category.root,
          },
          {
            title: t.store.faq.list,
            path: PATH_STORE.faq.faqList.root,
          },
        ],
      },
      {
        title: t.store.questionSurvey.title,
        path: PATH_STORE.questionSurvey.root,
        icon: ICONS.survey,
      },
      {
        title: t.store.ticket.title,
        path: PATH_STORE.ticket.root,
        icon: ICONS.ticket,
        children: [
          {
            title: t.store.ticket.title,
            path: PATH_STORE.ticket.ticketList.root,
          },
          {
            title: t.store.categoryTicket.title,
            path: PATH_STORE.ticket.ticketCategory.root,
          },
        ],
      },
      {
        title: t.store.userDoc.title,
        path: PATH_STORE.userDoc.root,
        icon: ICONS.userDoc,
      },
      {
        title: t.store.lms.title,
        path: PATH_STORE.lms.root,
        icon: ICONS.lms,
        children: [
          {
            title: t.store.lms.titleLms,
            path: PATH_STORE.lms.lmsList.root,
          },
          // {
          //   title: t.store.categoryTicket.title,
          //   path: PATH_STORE.ticket.ticketCategory.root,
          // },
        ],
      }, {
        title: t.store.media.title,
        path: PATH_STORE.media.root,
        icon: ICONS.media,

      },
    ],
  },
  {
    subheader: t.blog.title,
    items: [
      {
        title: t.blog.blog.title,
        path: PATH_BLOG.blog.root,
        icon: ICONS.blog,
        children: [
          {
            title: t.blog.blog.titleBlog,
            path: PATH_BLOG.blog.blogList.root,
          },
          {
            title: t.blog.blog.categoriesBlog,
            path: PATH_BLOG.blog.blogCategory.root,
          },
          {
            title: t.blog.blog.TagBlog,
            path: PATH_BLOG.blog.blogTag.root,
          },
        ],
      },
    ],
  },


];

export default navConfig;
